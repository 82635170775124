import { useRef } from "react";
import { useForm } from "react-hook-form";
import "./contacto.css";
import WhatsBoton from "../../componentes/WhatsBoton/WhatsBoton";
import InstaBoton from "../../componentes/InstaBoton/InstaBoton";

function Contacto() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      nombre: "",
      correo: "",
      asunto: "",
      mensaje: "",
    },
  });

  const password = useRef(null);
  password.current = watch("password", "");

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    await fetch ('http://localhost:8080/emails/contacto/', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    })
    .then ((response) => response.json ())
    .then ((data) => console.log(data))
    reset();
  });

  return (
    <div className="contenedorContacto">
      <form className="formulario" onSubmit={onSubmit}>
      <p className="formularioParrafo">Envianos Tu Consulta</p>
        <div className="formularioContenido">
          <div className="formularioGrupo">
            <input
              type="text"
              name="nombre"
              className="formularioEntrada"
              placeholder=" "
              {...register("nombre", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: 20,
                minLength: 2,
              })}
            />
            <label htmlFor="nombre" className="formularioEtiqueta">Nombre:</label>
            <span className="formularioLinea"></span>
            {errors.nombre?.type === "required" && (
              <span>Nombre requerido</span>
            )}
            {errors.nombre?.type === "maxLength" && (
              <span>Nombre no debe ser mayor a 20 caracteres</span>
            )}
            {errors.nombre?.type === "minLength" && (
              <span>Nombre debe ser mayor a 2 caracteres</span>
            )}
          </div>

          <div className="formularioGrupo">
            <input
              type="email"
              name="correo"
              className="formularioEntrada"
              placeholder=" "
              {...register("correo", {
                required: {
                  value: true,
                  message: "Correo es requerido",
                },
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Correo no válido",
                },
              })}
            />
            <label htmlFor="email" className="formularioEtiqueta">Correo Electrónico:</label>
            <span className="formularioLinea"></span>
            {errors.correo && <span>{errors.correo.message}</span>}
          </div>

          <div className="formularioGrupo">
            <input
              type="text"
              name="asunto"
              className="formularioEntrada"
              placeholder=" "
              {...register("asunto", {
                required: {
                  value: true,
                  message: "Asunto es requerido",
                },
                maxLength: 20,
                minLength: 2,
              })}
            />
            <label htmlFor="asunto" className="formularioEtiqueta">Asunto:</label>
            <span className="formularioLinea"></span>
            {errors.nombre?.type === "required" && (
              <span>Asunto requerido</span>
            )}
            {errors.nombre?.type === "maxLength" && (
              <span>Nombre no debe ser mayor a 20 caracteres</span>
            )}
            {errors.nombre?.type === "minLength" && (
              <span>Nombre debe ser mayor a 2 caracteres</span>
            )}
          </div>

          <div className="formularioGrupo">
            <textarea
              type="text"
              name="mensaje"
              className="formularioEntrada formularioEntradaTexto"
              placeholder=" "
              {...register("mensaje", {
                required: {
                  value: true,
                  message: "Mensaje es requerido",
                },
                maxLength: 20,
                minLength: 2,
              })}
            />
            <label htmlFor="mensaje" className="formularioEtiqueta">Mensaje:</label>
            <span className="formularioLinea"></span>
            {errors.nombre?.type === "required" && (
              <span>Mensaje requerido</span>
            )}
            {errors.nombre?.type === "maxLength" && (
              <span>Nombre no debe ser mayor a 20 caracteres</span>
            )}
            {errors.nombre?.type === "minLength" && (
              <span>Nombre debe ser mayor a 2 caracteres</span>
            )}
          </div>

          <button className="formularioEnviar" type="submit" >Enviar</button>
        </div>
      </form>
      <InstaBoton/>
      <WhatsBoton/>
    </div>
  );
}

export default Contacto;
